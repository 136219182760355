import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import BackBar from '@components/BackBar';
import axios from 'axios';
import * as config from '@config';
import styles from '@styles/TripPage.module.css';
import { MdAddCircleOutline } from 'react-icons/md';
import { Modal, Button, Icon, Form, TextArea, Dimmer, Loader, Checkbox, Popup, Label } from 'semantic-ui-react';
import { SelectorCityOptions } from '@utils/constans';
import CommissionAmount from '@components/CommissionAmount';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrips } from '@features/trips/tripsSlice';
import TripDestination from '@components/TripDestination';
import { DestinationName } from '@utils/constans';
import { GetToken, GetUserInfo } from '@utils/storage';

const defaultUserDefinedData = {
    userDefined: '',
    notes: '',
    public: false,
};

function TripPage(props) {
    const { tripID } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activities = useSelector((state) => state.activities.data);
    const trips = useSelector((state) => state.trips.data);
    const fetching = useSelector((state) => state.trips.fetching);
    const [data, setData] = useState({}); //trip
    const [dstPickerActive, setDstPickerActive] = useState(false);
    const [userDefinedActive, setUserDefinedActive] = useState(false);
    const [userDefinedData, setUserDefinedData] = useState(defaultUserDefinedData);
    const [submitting, setSubmitting] = useState(false);
    const [sortMode, setSortMode] = useState(false);
    const [compact, setCompact] = useState(false);
    const [exportTxtActive, setExportTxtActive] = useState(false);
    const loginInfo = GetUserInfo();

    const controller = new AbortController();

    useEffect(() => {
        if (tripID) {
            initalFetch();
        }
        return () => {
            if (fetching || submitting) controller.abort();
        };
    }, [tripID, trips]);

    useEffect(() => {
        // Reset setUserDefinedData
        if (userDefinedActive == true) {
            setUserDefinedData({
                userDefined: data.userDefined,
                notes: data.notes,
                public: data.public,
            });
        }
    }, [userDefinedActive]);

    const initalFetch = () => {
        const trip = trips.find((item) => item.id == tripID);
        if (!!trip) {
            setData(trip);
            setUserDefinedData({ userDefined: trip.userDefined, notes: trip.notes, public: trip.public });
        } else {
            fetchData();
        }
    };

    const fetchData = () => {
        dispatch(fetchTrips()).then((res) => {
            const trips = res.payload.trips;
            const trip = trips.find((item) => item.id == tripID);
            if (!!trip) {
                setData(trip);
                setUserDefinedData({ userDefined: trip.userDefined, notes: trip.notes, public: trip.public });
            } else {
                alert('没有找到行程');
                navigate(-1);
            }
        });
    };

    const handleOnCreate = (name) => {
        setSubmitting(true);
        const req = {
            tripID: data.id,
            names: [name],
        };
        const url = new URL(`${config.apiURL}/destinations`);
        axios
            .post(url, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                setDstPickerActive(false);
                fetchData();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleOnChange = (e, { name, value, checked }) => {
        if (name == 'public') {
            value = checked;
        }
        // console.log({ [name]: value });
        setUserDefinedData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleOnUpdate = () => {
        axios
            .put(`${config.apiURL}/trip/${tripID}`, userDefinedData, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                fetchData();
                setUserDefinedActive(false);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {});
    };

    const summary = () => {
        return (
            <div className={styles.total_cost_container}>
                <div>总门市价: {!!data.total && `A$${data.total}`}</div>
                <div>
                    总佣金: <CommissionAmount tripID={data.id} total={data.total} />
                </div>
            </div>
        );
    };

    //Switch to sorting mode
    const handleSortModeChange = () => {
        setSortMode((prev) => !prev);
    };

    const handleCompactChange = () => {
        setCompact((prev) => !prev);
    };

    const handleOnDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleOnDragOver = (e) => {
        e.preventDefault();
    };

    const handleOnDrop = (e, index) => {
        e.preventDefault();
        const draggedIndex = e.dataTransfer.getData('index');
        console.log(draggedIndex, 'dropped on', index);
        if (draggedIndex == index) {
            console.warn('Same position');
            return;
        }
        // console.log('before', articles);
        const newData = [...data.destinations];
        const dragged = newData[draggedIndex];
        newData.splice(draggedIndex, 1); //remove dragged one
        newData.splice(index, 0, dragged);
        // console.log('after', newData);
        const ids = newData.map((item) => item.id);
        handleUpdateSort(ids);
    };

    const handleUpdateSort = (ids) => {
        // console.log(ids);
        var req = { ids: ids };
        setSubmitting(true);
        axios
            .patch(`${config.apiURL}/destinations`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                fetchData();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const TripTextSummary = () => {
        return (
            <div className={styles.text_summary_container}>
                <div className={styles.text_summary_title}>{data.userDefined}</div>
                {data.destinations.map((destination, i) => {
                    return (
                        <div key={i} className={styles.text_summary_destination_container}>
                            <div className={styles.text_summary_destination_name}>
                                {DestinationName(destination.name)}
                            </div>
                            <ol>
                                {destination?.plays?.map((play, j) => {
                                    const activity = activities.find((item) => item.id == play.activityID);
                                    return (
                                        <li key={j} className={styles.text_summary_activity_name}>
                                            {activity.name}
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    );
                })}
            </div>
        );
    };

    const Order = () => {
        let counter = 0;
        data.destinations?.forEach((dst) => {
            counter = counter + dst.plays.length ?? 0;
        });
        if (counter > 0 && data.public == false) {
            return (
                <div className={styles.generate_order_container} onClick={handleGenerateOrder}>
                    <div className={styles.button}>
                        确定行程，前往预订 <Icon name="arrow alternate circle right outline" size="large" />
                    </div>
                </div>
            );
        } else {
            <></>;
        }
    };

    const handleGenerateOrder = () => {
        if (confirm('生成订单？') != true) {
            return;
        }
        setSubmitting(true);
        axios
            .post(
                `${config.apiURL}/trip/${tripID}/order`,
                {},
                {
                    signal: controller.signal,
                    headers: { Authorization: `Bearer ${GetToken()}` },
                }
            )
            .then((res) => {
                dispatch(fetchTrips());
                const orderID = res.data.orderID;
                if (orderID) {
                    navigate(`/dashboard/orders/${orderID}`, { replace: true });
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <div className={styles.leading_container}>
                <div className={styles.title}>
                    {data.userDefined} {!!data.public && <Label>模版</Label>} <Loader active={fetching} inline></Loader>
                </div>
                <div>
                    <button
                        className="primary_btn slim_btn"
                        onClick={() => setUserDefinedActive(true)}
                        title="修改名称和备注"
                    >
                        修改名称和备注
                    </button>
                    <div className="gap" />
                    <button className="primary_btn slim_btn" title="导出文字" onClick={() => setExportTxtActive(true)}>
                        导出文字
                    </button>
                    <Modal onClose={() => setExportTxtActive(false)} open={exportTxtActive} closeIcon>
                        <Modal.Header>{data.userDefined}</Modal.Header>
                        <Modal.Content scrolling>
                            <TripTextSummary />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="关闭" onClick={() => setExportTxtActive(false)} />
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
            <div className={styles.notes_container}>{data.notes}</div>
            <Modal
                open={userDefinedActive}
                onClose={() => setUserDefinedActive(false)}
                closeOnDimmerClick={false}
                closeIcon
            >
                <Modal.Header>自定义信息</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            fluid
                            name="userDefined"
                            label="名称"
                            placeholder="名称"
                            onChange={handleOnChange}
                            value={userDefinedData.userDefined || ''}
                        />
                        <Form.Field>
                            <label>备注</label>
                            <TextArea
                                rows={5}
                                name="notes"
                                placeholder="Notes"
                                value={userDefinedData.notes || ''}
                                onChange={handleOnChange}
                            />
                        </Form.Field>
                        {loginInfo.email.startsWith('guxkai') && (
                            <Form.Field>
                                <Checkbox
                                    name="public"
                                    checked={userDefinedData.public}
                                    label={
                                        <label>
                                            设置为模版
                                            <Popup
                                                basic
                                                content="其他用户可以看到并复制该行程"
                                                trigger={<Icon name="info circle" />}
                                            />
                                        </label>
                                    }
                                    onChange={handleOnChange}
                                />
                            </Form.Field>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setUserDefinedActive(false)}>取消</Button>
                    <Button onClick={handleOnUpdate} primary>
                        更新
                    </Button>
                </Modal.Actions>
            </Modal>

            {data.destinations?.length > 0 && summary()}
            <div className={styles.grid_container}>
                <div className={styles.functions_container}>
                    <Icon
                        size="large"
                        name={compact ? 'expand' : 'compress'}
                        className={styles.icon}
                        onClick={handleCompactChange}
                        title="紧凑"
                    />
                    <Icon
                        size="large"
                        name={!sortMode ? 'list' : 'sort'}
                        className={styles.icon}
                        onClick={handleSortModeChange}
                        title="排序"
                    />
                </div>

                {data.destinations?.map((item, index) => {
                    return (
                        <TripDestination
                            id={item.name}
                            key={index}
                            index={index}
                            destination={item}
                            inSortMode={sortMode}
                            draggable={sortMode}
                            callback={fetchData}
                            handleOnDragStart={handleOnDragStart}
                            handleOnDragOver={handleOnDragOver}
                            handleOnDrop={handleOnDrop}
                            compact={compact}
                        />
                    );
                })}
            </div>
            <div className={styles.bottom_container}>
                <div className={styles.destination_add_container}>
                    <div className={styles.button} onClick={() => setDstPickerActive(true)}>
                        <MdAddCircleOutline size={24} /> 添加目的地
                    </div>
                </div>
                <Order />
            </div>

            <Modal
                open={dstPickerActive}
                onClose={() => setDstPickerActive(false)}
                closeOnDimmerClick={false}
                closeIcon
            >
                <Modal.Header>选择观光地点</Modal.Header>
                <Modal.Content>
                    <div className={styles.dst_picker_container}>
                        {SelectorCityOptions.map((item, index) => {
                            return (
                                <div
                                    className={styles.dst_picker_container_item}
                                    key={index}
                                    onClick={() => handleOnCreate(item.value)}
                                >
                                    {item.name}
                                </div>
                            );
                        })}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setDstPickerActive(false)}>关闭</Button>
                </Modal.Actions>
            </Modal>
            <Dimmer active={submitting} page inverted>
                <Loader></Loader>
            </Dimmer>
        </div>
    );
}

TripPage.propTypes = {};

export default TripPage;
