import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from '@styles/TripDestinationPlays.module.css';
import { MdAddCircleOutline } from 'react-icons/md';
import { Modal, Button, Tab, Dimmer, Loader } from 'semantic-ui-react';
import { Selector } from '@utils/constans';
import axios from 'axios';
import * as config from '@config';
import TripDestinationPlaysRow from './TripDestinationPlaysRow';
import CommissionAmount from './CommissionAmount';
import ActivityPicker from '@components/activity/ActivityPicker';
import { DestinationName } from '@utils/constans';
import { createSelector } from '@reduxjs/toolkit';
import { GetToken } from '@utils/storage';

function TripDestinationPlays({ destination = {}, callback = () => {}, compact }) {
    // Redux Memoized Selectors
    const selectorData = (state) => state.activities.data;
    const filterHiden = createSelector(selectorData, (items) => items?.filter((item) => item.hidden == false));
    const activities = useSelector((state) => {
        return filterHiden(state);
    });
    const fetching = useSelector((state) => state.activities.fetching);
    const [activityPickerActive, setActivityPickerActive] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [cars, setCars] = useState([]);
    const [cityPackages, setCityPackages] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        filterTickets();
        filterCars();
        filterCityPackage();
        return () => {};
    }, [fetching]);

    const filterTickets = () => {
        const selector = destination.name;
        let newData = [...activities];
        newData = activities.filter((item) => {
            if (
                !item.selectors?.includes(Selector.Cars) &&
                !item.selectors?.includes(Selector.Cruises) &&
                !item.selectors?.includes(Selector.Team) &&
                !item.selectors?.includes(Selector.Package) &&
                !item.selectors?.includes(Selector.OtherExperience)
            ) {
                return true;
            }
        });
        newData = newData.filter((item) => {
            if (item.selectors?.includes(selector)) {
                return true;
            }
        });
        // sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == selector).sort;
            const sortB = b.selectorItems.find((item) => item.name == selector).sort;
            return sortA - sortB;
        });
        setTickets(newData);
    };

    const filterCars = () => {
        const selector = destination.name;
        let newData = [...activities];
        newData = newData.filter((item) => {
            if (item.selectors?.includes(selector) && item.selectors?.includes(Selector.Cars)) {
                return true;
            }
        });
        // sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == selector).sort;
            const sortB = b.selectorItems.find((item) => item.name == selector).sort;
            return sortA - sortB;
        });
        setCars(newData);
    };

    const filterCityPackage = () => {
        const selector = destination.name;
        let newData = [...activities];
        newData = newData.filter((item) => {
            if (item.selectors?.includes(selector) && item.selectors?.includes(Selector.Package)) {
                return true;
            }
        });
        // sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == selector).sort;
            const sortB = b.selectorItems.find((item) => item.name == selector).sort;
            return sortA - sortB;
        });
        setCityPackages(newData);
    };

    const handleAdd = (activityID) => {
        setSubmitting(true);
        const req = {
            activityID: activityID,
            destinationID: destination.id,
        };
        const url = new URL(`${config.apiURL}/plays`);
        axios
            .post(url, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                // const activity = activities.find((item) => item.id == activityID);
                // if (activity) {
                //     alert(`已添加"${activity.name}"`);
                // }
                callback();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const summary = () => {
        if (destination?.plays?.length > 0) {
            return (
                <div className={styles.summary_container}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>门市价: {`A$${destination.total}`}</div>
                    <div>
                        佣金: <CommissionAmount dstID={destination.id} total={destination.total} />
                    </div>
                    <div></div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const handleTabChange = (e, data) => {
        setTabIndex(data.activeIndex);
    };

    return (
        <div className={styles.container}>
            {destination?.plays.length > 0 ? (
                <div className={styles.plays_container}>
                    {summary()}
                    {destination.plays.map((item, index) => {
                        return (
                            <div className={styles.play_container} key={index}>
                                <TripDestinationPlaysRow play={item} compact={compact} callback={callback} />
                            </div>
                        );
                    })}
                    {summary()}
                </div>
            ) : (
                <div>请添加体验或包车</div>
            )}

            <div className={styles.bottom_container} onClick={() => setActivityPickerActive(true)}>
                <div className={styles.button}>
                    <MdAddCircleOutline size={24} /> 添加活动
                </div>
            </div>
            <Modal
                open={activityPickerActive}
                onClose={() => setActivityPickerActive(false)}
                closeOnDimmerClick={false}
                closeIcon
                size="large"
                centered={false}
            >
                <Modal.Header>{DestinationName(destination.name)}</Modal.Header>
                <Modal.Content scrolling>
                    <div className={styles.activity_picker_container}>
                        <Tab
                            menu={{ secondary: true, pointing: false }}
                            activeIndex={tabIndex}
                            onTabChange={handleTabChange}
                            panes={[
                                {
                                    menuItem: '体验',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                activities={tickets}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                                {
                                    menuItem: '城市套餐',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                activities={cityPackages}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                                {
                                    menuItem: '包车',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                activities={cars}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setActivityPickerActive(false)}>确认</Button>
                </Modal.Actions>
                <Dimmer active={submitting} page inverted>
                    <Loader></Loader>
                </Dimmer>
            </Modal>
        </div>
    );
}

TripDestinationPlays.propTypes = {
    destination: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        plays: PropTypes.array,
        notes: PropTypes.string,
        sort: PropTypes.number,
    }).isRequired,
    callback: PropTypes.func.isRequired,
    compact: PropTypes.bool,
};

export default TripDestinationPlays;
